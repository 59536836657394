import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/BlogPage/HeroSection/HeroSection"
import Tabs from "../components/BlogPage/Tabs/Tabs"
import SignUpForNewsLetter from "../components/BlogPage/SignUpForNewsLetter/SignUpForNewsLetter"
import { graphql } from "gatsby"
import SEO from "../components/Global/SEO/seo"

const blog = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges
  const categoryName = pageContext.category && (pageContext.category.charAt(0).toUpperCase() + pageContext.category.slice(1));
  return (
    <Layout>
      <SEO page="blog" title={categoryName? `${categoryName} | Blog`: null}/>
      <HeroSection />
      <Tabs
        posts={posts}
        showFeatured={false}
        showCategory={false}
        pageContext={pageContext}
      />

    </Layout>
  )
}

export default blog

export const query = graphql`
  query Getposts($skip: Int!, $limit: Int!, $category: [String]) {
    allContentfulBlogPost(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: publishedOn }
      filter: {
        category: { elemMatch: { slug: { in: $category, nin: "internal" } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          publishedOn(formatString: "DD MMMM YYYY")
          previewImage {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          category {
            name
            slug
          }
          externalLink
        }
      }
    }
  }
`
